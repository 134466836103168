main {
    min-height: 83vh;
}
/*body {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

strong{
    font-weight: bold;
}

.homepage_card {
    margin-bottom: 30px;
}

.banner_heading {
    text-align: center;
    padding: 10px 0;
    margin-top: 30px;
}

.Cards_for_dash {
    height: auto;
    max-height: 550px;
    width: auto;
    max-width: 550px;
}

.single-client-info{
    text-align: center;
    padding: 10px 0;
    margin-top: 30px;
}

.processImages{
    width: 400px;
    height: 500px;
    margin: 15px 0 15px 0;
}

.floorPlanImage{
    width: 100px;
    height: 110px;
}

.workOrderRow{
    padding: 30px 0;
    transition: 0.3s;
    background-color: white;
    /*border-bottom: 1px solid gray;*/
}


.workOrderRow:hover {
    background-color: lightblue;
    transition: 0.3s;
    border: none;
    color: black;
    padding: 16px 32px;
    font-size: 16px;
    margin: 4px 2px;
}

.AddHourRow{
    padding: 15px 0 0 0;
}

.AddHourCol{
    padding: 0 2px 0 2px;
    margin: 0 2px 15px 2px;
}

.HourPerRow{
    padding: 20px 0 20px 0;
}

.HoursSpan{
    padding-bottom: 20px;
}

.perposalInvoiceHeaders{
    text-align: center;
    padding: 15px 0 15px 0;
}

.loadingSpinner{
    margin: auto;
    width: 50%;
    padding: 10px;
}

.reports_nav_heading{
    padding: 15px 0 20px 0;
}

.statusReportHeader{
    padding: 15px 0 25px 0;
    text-align: center;
}

.newPaymentSpan{
    padding:10px;
    margin:10px 10px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius:10px;
    background-color: black;
    color: white;
}

.interestPaymentSpan{
    padding:10px;
    margin:10px 10px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius:10px;
    /*background-color: #DC5F00;*/
    color: white;
    background-color: #CF0A0A;
}

.reportsSum{
    padding: 10px;
    background-color: black;
    color: white;
    margin-top: 10px;
    text-align: center;
}
.PassElement{
    display: none;
    margin: 0;
    padding: 0;
}
.logisticsWorkOrderRow{
    padding: 20px 0;
    transition: 0.3s;
    background-color: white;
}


.collapse-area{
    padding: 20px;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}
.pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
}
.pagination > li > a{
    border: 1px solid black;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: black;
    border-color: black;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: black;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
}

.disabled-link {
    pointer-events: none;
}

.HomePageElementWhatWeDo{
    color: black;
}

.ourMission{
    background-image: linear-gradient(to bottom right, #e47125, #e6b79a)
}

.homePageServices{
    background-image: linear-gradient(to bottom right, #FFDCAE, #FFC93C)
}

.radonProcess{
    background-image: linear-gradient(to bottom right, #3AB4F2, #0078AA)
}

.moldRemoval{
    background-image: linear-gradient(to bottom right, #ADCF9F, #54B435)
}

.contactUsStyle{
    background-image: linear-gradient(to bottom right, darkgray, lightgray)
}

.homePageServicesText{
    color: black;
}

.homePageRow{
    padding: 30px 0;
    transition: 0.3s;
}

.homePageRow:hover{
    transition: 0.3s;
    border: none;
    padding: 16px 32px;
    font-size: 16px;
    margin: 4px 2px;
}

.carousel {
    /*width:900px;*/
    /*height:500px;*/
    margin: auto;
    width: 90%;
    height: 90%;
    /*width: auto;*/
    /*height: auto;*/
}
.WaltHenry {
    padding: 50px;
    background-image: linear-gradient(to bottom right, #e47125, #e6b79a);
    color: black;
    height: 50%;
    width: auto;
}

.landingPagePics{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto;
    border-radius: 25px;
    /*max-height: 560px;*/
    /*max-width: 525px;*/
    max-height: 90%;
    max-width: 90%;
}

.pTagsForHomeScreen{
    color: black;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .landingPagePics{
        display: block;
        margin-top: 50%;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;
        border-radius: 25px;
        /*max-height: 560px;*/
        /*max-width: 525px;*/
        max-height: 90%;
        max-width: 90%;
    }
    .homePageCarousel{
        width: auto;
        /*height: auto;*/
        height: 200px;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .homePageCarousel{
        width: auto;
        /*height: auto;*/
        height: 235px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .homePageCarousel{
        width: auto;
        /*height: auto;*/
        height: 310px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .homePageCarousel{
        width: auto;
        /*height: auto;*/
        height: 430px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .homePageCarousel{
        width: auto;
        /*width: 700px;*/
        /*height: auto;*/
        height: 590px;
    }
}


